import React from 'react';
import './MentionsLegales.css';
import enveloppe from './Image/enveloppe3.png';

const MentionsLegales = () => {
  return (
    <div className="mentions-container">
      <h1>Conditions générales</h1>
      <p class="standard">L’entreprise La Ferme du Fersac, dont le siège social est situé au <a href="https://maps.app.goo.gl/6tGXdtbLyaXfvywB9" target="_blank" rel="" className="map-link2">26 rue Mle du Vautenet à Meillac</a> (35270), est enregistrée sous le numéro de SIRET 929 666 576 00012.</p>
      <h3>Hébergeur :</h3>
      <p class="liste">Firebase, une plateforme de Google LLC</p>
      <p class="liste">Siège social : 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis</p>
      <br/>
      <h3>Création / Réalisation :</h3>
      <p class="liste"> Ce site internet a été réalisé par <a href="https://www.linkedin.com/in/youen-hamon-3538b42b2/" target="_blank" rel="" className="link">Youen HAMON</a></p>
      <div className="contact-info">
        <img src={enveloppe} alt="Enveloppe" className="enveloppe-icon" />
        <a href="mailto:youenhamon@gmail.com" className="email-link">youenhamon@gmail.com</a>
      </div>
    </div>
  );
};

export default MentionsLegales;