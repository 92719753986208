import React, { useEffect, useState } from "react";
import { listenActu, addActu, uploadImage, deleteActu, updateActu } from "./firestore"; // Importer deleteActu
import "./Actu.css";
import Loader from "./Loader";
import Footer from './Footer';
import { useAuth } from "./AuthContext";

const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];

const Actu = ({ hidden, toggleFooter }) => {
    const [actu, setActu] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingActu, setIsAddingActu] = useState(false);
    const [isHelpVisible, setIsHelpVisible] = useState(false); // État pour contrôler la visibilité de l'aide
    const [newActu, setNewActu] = useState({ title: "", body: "", color: "", image: "", publication: "" });
    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState("");
    const { user } = useAuth();
    const isAdmin = user && adminEmails.includes(user.email);
    const [currentActu, setCurrentActu] = useState(null);
    const [isEditingActu, setIsEditingActu] = useState(false);

    useEffect(() => {
        const unsubscribe = listenActu((newActu) => {
            setActu(newActu);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleAddActu = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = "";
            if (selectedImage) {
                imageUrl = await uploadImage(selectedImage);
            }
            await addActu({ ...newActu, image: imageUrl });
            setNewActu({ title: "", body: "", color: "", image: "", publication: "" });
            setSelectedImage(null);
            setIsAddingActu(false);
        } catch (err) {
            setError(`Erreur lors de l'ajout de l'actualité : ${err.message}`);
        }
    };

    const handleDeleteActu = async (id) => {
        try {
            await deleteActu(id);
        } catch (err) {
            setError(`Erreur lors de la suppression de l'actualité : ${err.message}`);
        }
    };

    const handleEditActu = (actu) => {
        setCurrentActu(actu);
        setNewActu({ title: actu.Title, body: actu.body, color: actu.color, image: actu.image, publication: actu.publication });
        setIsEditingActu(true);
    };

    const handleUpdateActu = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = currentActu.image;
            if (selectedImage) {
                imageUrl = await uploadImage(selectedImage);
            }
            await updateActu(currentActu.id, { ...newActu, image: imageUrl });
            setNewActu({ title: "", body: "", color: "", image: "", publication: "" });
            setSelectedImage(null);
            setIsEditingActu(false);
            setCurrentActu(null);
        } catch (err) {
            setError(`Erreur lors de la mise à jour de l'actualité : ${err.message}`);
        }
    };

    const formatDate = (date) => {
        if (date instanceof Date) {
            return date.toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        } else if (date.seconds) { // Vérifie si c'est un objet Timestamp
            return new Date(date.seconds * 1000).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        } else {
            return new Date(date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        }
    };

    const formatText = (text) => {
        return text.replace(/\n/g, '<br>');
    };

    return (
        <div hidden={hidden}>
            <h1>Nos Actualités</h1>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {isAdmin && (
                        <button className="add-button" onClick={() => setIsAddingActu(true)}>Ajouter une actu</button>
                    )}
                    {actu.map((element, index) => (
                        <div key={index} className="test">
                            <div className="container2">
                                <div className="actualite">
                                    <img src={element.image} alt="Image actualité" />
                                    <div className="texte">
                                        <h2 style={{ color: element.color }}>
                                            {element.Title}
                                        </h2>
                                        <p dangerouslySetInnerHTML={{ __html: formatText(element.body) }}></p>
                                        <div className="publication">
                                            <p>{"Publié le " + formatDate(element.publication)}</p>
                                        </div>
                                        {isAdmin && (
                                            <>
                                                <button className="edit-button" onClick={() => handleEditActu(element)}>Modifier</button>
                                                <button className="delete-button-actu" onClick={() => handleDeleteActu(element.id)}>Supprimer</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {(isAddingActu || isEditingActu) && (
                        <div className="actu-upload">
                            <form onSubmit={isEditingActu ? handleUpdateActu : handleAddActu}>
                                <h2>{isEditingActu ? "Modifier l'actualité" : "Ajouter une actualité"}</h2>
                                <input
                                    type="text"
                                    placeholder="Titre"
                                    value={newActu.title}
                                    onChange={(e) => setNewActu({ ...newActu, title: e.target.value })}
                                    required
                                />
                                <textarea
                                    placeholder="Texte"
                                    value={newActu.body}
                                    onChange={(e) => setNewActu({ ...newActu, body: e.target.value })}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Couleur du titre"
                                    value={newActu.color}
                                    onChange={(e) => setNewActu({ ...newActu, color: e.target.value })}
                                    required
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => setSelectedImage(e.target.files[0])}
                                />
                                <input
                                    type="date"
                                    value={newActu.publication}
                                    onChange={(e) => setNewActu({ ...newActu, publication: e.target.value })}
                                    required={!isEditingActu} // Champ obligatoire uniquement lors de l'ajout
                                />
                                <div className="button-container">
                                    <button type="submit">{isEditingActu ? "Mettre à jour" : "Ajouter l'actualité"}</button>
                                    <button type="button" onClick={() => { setIsAddingActu(false); setIsEditingActu(false); }}>Annuler</button>
                                    <button type="button" onClick={() => setIsHelpVisible(true)}>Aide</button>
                                </div>
                                {error && <p className="error">{error}</p>}
                            </form>
                        </div>
                    )}
                    {isHelpVisible && (
                        <HelpOverlay onClose={() => setIsHelpVisible(false)} />
                    )}
                    <Footer setShowMentionsLegales={toggleFooter} />
                </>
            )}
        </div>
    );
};

const HelpOverlay = ({ onClose }) => {
    return (
        <div className="help-overlay">
            <div className="help-content">
                <h2>Aide</h2>
                <p className="help">Pour la zone "texte" c'est exactement comme dans l'intro de accueil, c'est à dire que tu peux mettre du texte en couleur, en gras, en italique ou/et le souligner. Pour ça il suffit de mettre le texte entre balise. 
                <br/>
                <code>&lt;b&gt;gras&lt;/b&gt;</code>
                <br/>
                <code>&lt;i&gt;italique&lt;/i&gt;</code>
                <br/>
                <code>&lt;u&gt;souligner&lt;/u&gt;</code>
                <br/>
                <code>&lt;b&gt;&lt;i&gt;texte en gras et en italique&lt;/i&gt;&lt;/b&gt;</code>
                <br/>
                <code>&lt;span style=&quot;color: blue;&quot;&gt;texte en bleu&lt;/span&gt;</code> (les couleurs sont en anglais)
                <br/>
                <code>&lt;span style=&quot;color: red;&quot;&gt;&lt;u&gt;texte rouge + souligné&lt;/u&gt;&lt;/span&gt;</code>
                </p>

                <p className="help">Pour la zone couleur ça correspond à la couleur du titre (à mettre en anglais ou en  héxadécimale voir site : https://htmlcolorcodes.com/fr/)
                <br/>
                La couleur du tronc de l'arbre (vert foncé) en héxadécimale c'est #2A4C21
                <br/> 
                La couleur des feuilles de l'arbre (vert clair) en héxadécimale c'est #83BC2A
                <br/> 
                La couleur de la citrouille (orange) en héxadécimale c'est #F07D0C
                <br/> 
                </p>
                <button className="edit-button" onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
};

export default Actu;